<template>
  <div id="main">
    <mv-full-page
      :isPointer="false"
      :isV="isV"
      :pages="pageCount"
      :page.sync="currentPage"
      :isCache="true"
      :transition="{
        duration: '500ms', // 动画时长
        timingFun: 'ease', // 动画速度曲线
        delay: '0s', // 动画延迟
      }"
      @rollEnd="onRollEnd"
      :config="{
        disabled: this.localValue
      }"
    >
      <template #page1>
        <div class="e10-video">
          <video-play videoSrc="//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/home_e10.mp4"></video-play>
          <p class="e10-title" v-animate="{
              name: 'fadeInUp',
              delay: 500,
            }">盆底元气弹</p>
          <p class="e10-desc" v-animate="{
              name: 'fadeInUp',
              delay: 500,
            }">沐澜 E10 series 盆底康复治疗仪</p>
          <div class="e10-btn home-btn white" v-animate="{
              name: 'fadeInUp',
              delay: 1000,
            }">
            <div class="bg"></div>
            <div class="mask"></div>
            <div class="text" @click="goProduct('e10')">新品上市，探索更多</div>
          </div>
        </div>
      </template>

      <template #page2>
        <div class="t20-video">
          <video-play videoSrc="//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/home_t20.mp4"></video-play>
          <p class="t20-title" v-animate="{
              name: 'fadeInUp',
              delay: 500,
            }">精准治疗，紧致革新</p>
          <p class="t20-desc" v-animate="{
              name: 'fadeInUp',
              delay: 500,
            }">澜渟 T20 series 盆底康复治疗仪</p>
          <div class="t20-btn home-btn white" v-animate="{
              name: 'fadeInUp',
              delay: 1000,
            }">
            <div class="bg"></div>
            <div class="mask"></div>
            <div class="text" @click="goProduct('t20')">了解更多</div>
          </div>
        </div>
      </template>
      <template #page3>
        <div class="product-poster">
          <div class="swiper-content">
            <swiper ref="swiper" :datas="productPoster"></swiper>
          </div>
        </div>
      </template>
      <template #page4>
        <div class="home-app">
          <div class="app-list" v-animate="{
              name: 'fadeInUp',
              delay: 500,
            }">
            <div class="app-item">
              <img :src="require('@/assets/img/home/lt-app.png')" alt="">
              <p class="app-name">澜渟用户版</p>
              <p class="app-desc">凯格尔训练指导</p>
            </div>
            <div class="app-item">
              <img :src="require('@/assets/img/home/ltd-app.png')" alt="">
              <p class="app-name">澜渟医生版</p>
              <p class="app-desc">医生进阶学习</p>
            </div>
          </div>
          <div class="app-right">
            <p class="app-title" v-animate="{
              name: 'fadeInUp',
              delay: 700,
            }">澜渟智能服务平台</p>
            <p class="app-title-2" v-animate="{
              name: 'fadeInUp',
              delay: 900,
            }">家用盆底康复软件</p>
            <p class="app-title-3" v-animate="{
              name: 'fadeInUp',
              delay: 1100,
            }">两大盆底 App</p>
            <p class="app-title-4" v-animate="{
              name: 'fadeInUp',
              delay: 1300,
            }">助力盆底康复行业智慧化升级</p>
            <div class="app-btn home-btn black" v-animate="{
              name: 'fadeInUp',
              delay: 1500,
            }">
              <div class="bg"></div>
              <div class="mask"></div>
              <div class="text" @click="goApp">点击进入下载页</div>
            </div>
          </div>
        </div>
      </template>
      <template #page5>
        <div class="home-about">
          <img alt="" :src="require('@/assets/img/home/about.png')">
          <div class="about">
            <p v-animate="{
              name: 'fadeInUp',
              delay: 500,
            }">关于澜渟</p>
            <p v-animate="{
              name: 'fadeInUp',
              delay: 700,
            }">专研家用盆底医学品牌</p>
            <div class="about-btn home-btn white" v-animate="{
              name: 'fadeInUp',
              delay: 900,
            }">
              <div class="bg"></div>
              <div class="mask"></div>
              <div class="text" @click="goAbout('info')">了解更多</div>
            </div>
          </div>
        </div>
      </template>
      <template #page6>
        <div class="home-more">
          <p class="title" v-animate="{
              name: 'fadeInUp',
              delay: 500,
            }">更多信息</p>
          <div class="card-list">
            <div v-animate="{
              name: 'fadeInUp',
              delay: 700,
            }" class="card left-card" @click="goTech">
              <img alt="" :src="require('@/assets/img/home/more-1.png')">
              <p>核心技术</p>
              <div class="left-btn home-btn black-outline">
                <div class="bg"></div>
                <div class="mask"></div>
                <div class="text">了解更多</div>
              </div>
            </div>
            <div class="right-card">
              <div class="card top-card" v-animate="{
              name: 'fadeInUp',
              delay: 900,
            }" @click="goAbout('consultation')">
                <img alt="" :src="require('@/assets/img/home/more-2.png')">
                <p>新闻中心</p>
                <div class="top-btn home-btn black-outline">
                  <div class="bg"></div>
                  <div class="mask"></div>
                  <div class="text">了解更多</div>
                </div>
              </div>
              <div class="card bottom-card" v-animate="{
              name: 'fadeInUp',
              delay: 1100,
            }" @click="goAbout('concat')">
                <img alt="" :src="require('@/assets/img/home/more-3.png')">
                <p>联系我们</p>
                <div class="bottom-btn home-btn black-outline">
                  <div class="bg"></div>
                  <div class="mask"></div>
                  <div class="text">了解更多</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #page7>
        <div class="home-mall">
          <div class="mall-left mall-card">
            <img alt="" :src="require('@/assets/img/home/mall-1.png')">
            <div class="mall-content">
              <p>澜渟品牌店铺</p>
              <div class="btn-list">
                <div class="mall-btn home-btn white" @click="openUrl('https://mall.jd.com/index-1000303621.html')">
                  <div class="bg"></div>
                  <div class="mask"></div>
                  <div class="text">
                    <img alt :src="require('@/assets/img/home/mall-jd.png')"/>
                    京东旗舰店
                    <img alt :src="require('@/assets/img/home/mall-to.png')"/>
                  </div>
                </div>
                <div class="mall-btn home-btn white" @click="openUrl('https://lanting.tmall.com/')">
                  <div class="bg"></div>
                  <div class="mask"></div>
                  <div class="text">
                    <img alt :src="require('@/assets/img/home/mall-tmall.png')"/>
                    天猫旗舰店
                    <img alt :src="require('@/assets/img/home/mall-to.png')"/>
                  </div>
                </div>
                <div class="mall-btn home-btn white" @click="openUrl('https://www.douyin.com/user/MS4wLjABAAAAygVxn6nKRVHFNr3cmNjeHui-HLuc2AKUewfldBd4iOyyQCHzlPlfh66oFejjkaG6')">
                  <div class="bg"></div>
                  <div class="mask"></div>
                  <div class="text">
                    <img alt :src="require('@/assets/img/home/mall-tiktok.png')"/>
                    抖音旗舰店
                    <img alt :src="require('@/assets/img/home/mall-to.png')"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mall-right mall-card">
            <img alt="" :src="require('@/assets/img/home/mall-2.png')">
            <div class="mall-content">
              <p>沐澜品牌店铺</p>
              <div class="btn-list">
                <div class="mall-btn home-btn white" @click="openUrl('https://mall.jd.com/index-1000477192.html')">
                  <div class="bg"></div>
                  <div class="mask"></div>
                  <div class="text">
                    <img alt :src="require('@/assets/img/home/mall-jd.png')"/>
                    京东旗舰店
                    <img alt :src="require('@/assets/img/home/mall-to.png')"/>
                  </div>
                </div>
                <div class="mall-btn home-btn white" @click="openUrl('https://mulanylqx.tmall.com')">
                  <div class="bg"></div>
                  <div class="mask"></div>
                  <div class="text">
                    <img alt :src="require('@/assets/img/home/mall-tmall.png')"/>
                    天猫旗舰店
                    <img alt :src="require('@/assets/img/home/mall-to.png')"/>
                  </div>
                </div>
                <div class="mall-btn home-btn white" @click="openUrl('https://www.douyin.com/user/MS4wLjABAAAA78SSTMpNITb9W_mO0RoBY84ovdjRO6qQZDM6qiUxqNnydnQP7mbJAiYTrZZpLfF2')">
                  <div class="bg"></div>
                  <div class="mask"></div>
                  <div class="text">
                    <img alt :src="require('@/assets/img/home/mall-tiktok.png')"/>
                    抖音旗舰店
                    <img alt :src="require('@/assets/img/home/mall-to.png')"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </mv-full-page>
  </div>
</template>

<script>
import '@/assets/css/animate.min.css';
import 'mv-full-page/lib-dist/mv-full-page.css';
import MvFullPage from 'mv-full-page';
import Vue from 'vue';
import { VideoPlay } from '@/components/video';
import { swiper } from '@/components/swiper';
import directives from '@/utils/directives';
Vue.use(directives);
Vue.use(MvFullPage);

export default {
  data() {
    return {
      localValue: this.value,
      isV: true,
      currentPage: 1,
      pageCount: 7,
      productPoster: [
        {
          path: require('@/assets/img/home/product-poster-3.png'),
          title: 'E10 series 盆底康复治疗仪',
          desc: '科学维稳紧致，重塑健康盆底',
          event: () => {this.goProduct('e10');},
        },
        {
          path: require('@/assets/img/home/product-poster-4.png'),
          title: 'T20 series 盆底康复治疗仪',
          desc: '双重修复，紧致系统',
          event: () => {this.goProduct('t20');},
        },
        {
          path: require('@/assets/img/home/product-poster-5.png'),
          title: '初澜盆底生物刺激反馈仪',
          desc: '盆底黑科技，贴身盆底康复师',
          event: () => {this.goProduct('wrightin');},
        },
        {
          path: require('@/assets/img/home/product-poster-6.png'),
          title: 'T20 Max 盆底肌修复仪',
          desc: '盆底抗衰一体机',
          event: () => {this.goProduct('wrightinTwo');},
        },
        {
          path: require('@/assets/img/home/product-poster-1.png'),
          title: '盆底肌肉康复器',
          desc: '阴道哑铃锻炼阴道肌肉',
          event: () => {this.goProduct('kegel');},
        },
        {
          path: require('@/assets/img/home/product-poster-2.png'),
          title: '洛澜盆底康复治疗仪',
          desc: '多维修复，医疗级诊疗方案',
          event: () => {this.goProduct('luolan');},
        },
      ],
    };
  },
  props: {
    value: { //数据源（必填）
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$emit('rollInit');
  },
  methods: {
    /**
     页面跳转-产品
     @param {String} route 路由
     @return
     */
    goProduct(route) {
      this.$router.push({path: '/product/' + route});
    },

    goApp() {
      this.$router.push({path: '/app'});
    },

    goAbout(route) {
      this.$router.push({path: '/about/' + route});
    },

    goTech() {
      this.$router.push({path: '/technology'});
    },

    openUrl(url) {
      window.open(url);
    },

    onRollEnd(page) {
      this.$emit('rollPage', page);
      if (page === this.pageCount) {
        this.$emit('rollEnd');
      } else {
        this.$emit('rollTop');
      }
    },
  },
  watch: {
    value(val) {
      this.localValue = val;
    },
    currentPage(val) {
      if (val === 1) {
        this.localValue = false;
      }
    },
  },
  components: {
    VideoPlay,
    swiper,
  },
};
</script>

<style lang="scss" scoped>
.home-btn {
  padding: 0.06rem 0.24rem;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  > .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .4s cubic-bezier(.4, 0, .2, 1);
    z-index: 1;
  }

  > .mask {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #7F4DC9;
    transition: all .4s cubic-bezier(.4, 0, .2, 1);
    z-index: 2;
  }

  > .text {
    z-index: 3;
    font-size: 0.2rem;
    line-height: 0.28rem;
    white-space: nowrap;
  }

  &:hover, &:active, &:focus {
    > .bg {
      height: 0;
    }

    > .mask {
      height: 100%;
    }
  }

  &.white {
    > .bg {
      background-color: #fff;
    }

    > .text {
      color: #000;
    }

    &:hover, &:active, &:focus {
      > .text {
        color: #fff;
      }
    }
  }

  &.black {
    > .bg {
      background-color: #000;
    }

    > .text {
      color: #fff;
    }

    &:hover, &:active, &:focus {
      > .text {
        color: #fff;
      }
    }
  }

  &.black-outline {
    border: 0.01rem solid #000000;
    transition: all .4s cubic-bezier(.4, 0, .2, 1);
    > .bg {
      background-color: transparent;
    }

    > .text {
      color: #000;
      white-space: nowrap;
    }

    &:hover, &:active, &:focus {
      border-color: transparent;
      > .text {
        color: #fff;
      }
    }
  }
}

.e10-video {
  position: relative;
  width: 100%;
  height: 100%;

  > .e10-title {
    position: absolute;
    left: 0.9rem;
    bottom: 2.23rem;
    font-weight: 500;
    font-size: 0.28rem;
    color: #FFFFFF;
    line-height: 0.39rem;
  }

  > .e10-desc {
    position: absolute;
    left: 0.9rem;
    bottom: 1.72rem;
    font-weight: 500;
    font-size: 0.28rem;
    color: #FFFFFF;
    line-height: 0.39rem;
  }

  > .e10-btn {
    position: absolute;
    left: 0.9rem;
    bottom: 1rem;
  }
}

.t20-video {
  position: relative;
  width: 100%;
  height: 100%;

  > .t20-title {
    position: absolute;
    left: 0.9rem;
    bottom: 2.23rem;
    font-weight: 500;
    font-size: 0.28rem;
    color: #FFFFFF;
    line-height: 0.39rem;
  }

  > .t20-desc {
    position: absolute;
    left: 0.9rem;
    bottom: 1.72rem;
    font-weight: 500;
    font-size: 0.28rem;
    color: #FFFFFF;
    line-height: 0.39rem;
  }

  > .t20-btn {
    position: absolute;
    left: 0.9rem;
    bottom: 1rem;
  }
}

.product-poster {
  position: relative;
  width: 100%;
  height: 100%;
  background: #FAFBFE;

  > .swiper-content {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 9.68rem;
  }
}

.home-app {
  position: relative;
  width: 100%;
  height: 100%;
  background: #FAFBFE;

  > .app-list {
    position: absolute;
    top: 0;
    left: 2.18rem;
    width: 7.48rem;
    height: 100%;
    padding-top: 0.72rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .app-item {
      > img {
        width: 3.34rem;
        height: 6.88rem
      }

      > .app-name {
        margin-top: 0.3rem;
        text-align: center;
        font-weight: 500;
        font-size: 0.22rem;
        color: #000000;
        line-height: 0.31rem;
      }

      > .app-desc {
        margin-top: 0.12rem;
        text-align: center;
        font-size: 0.2rem;
        color: #999999;
        line-height: 0.28rem;
      }
    }
  }

  > .app-right {
    position: absolute;
    left: 12.46rem;
    padding-top: 0.72rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .app-title {
      font-weight: 500;
      font-size: 0.56rem;
      color: #000000;
      line-height: 0.78rem;
    }

    > .app-title-2 {
      margin-top: 0.12rem;
      font-weight: 500;
      font-size: 0.28rem;
      color: #000000;
      line-height: 0.39rem;
    }

    > .app-title-3 {
      margin-top: 0.6rem;
      font-weight: 500;
      font-size: 0.28rem;
      color: #000000;
      line-height: 0.39rem;
    }

    > .app-title-4 {
      margin-top: 0.12rem;
      font-size: 0.2rem;
      color: #999999;
      line-height: 0.28rem;
    }

    > .app-btn {
      position: relative;
      width: 1.88rem;
      height: 0.4rem;
      margin-top: 0.6rem;
    }
  }
}

.home-about {
  position: relative;
  width: 100%;
  height: 100%;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > .about {
    position: absolute;
    left: 2.26rem;
    padding-top: 0.72rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > p:nth-child(1) {
      font-weight: 500;
      font-size: 0.56rem;
      color: #FFFFFF;
      line-height: 0.78rem;
    }

    > p:nth-child(2) {
      margin-top: 0.12rem;
      font-weight: 500;
      font-size: 0.28rem;
      color: #FFFFFF;
      line-height: 0.39rem;
    }

    > .about-btn {
      position: relative;
      width: 1.28rem;
      height: 0.4rem;
      margin-top: 0.6rem;
    }
  }
}

.home-more {
  position: relative;
  padding-top: 0.72rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .title {
    font-weight: 500;
    font-size: 0.56rem;
    color: #000000;
    line-height: 0.78rem;
  }

  > .card-list {
    margin-top: 0.6rem;
    display: flex;
    justify-content: space-between;
    gap: 0.24rem;

    .card {
      overflow: hidden;
      cursor: pointer;
      border-radius: 0.24rem;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        >img {
          transform: scale(1.1);
        }
      }

      > img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        transition: all 0.4s;
      }

      > p {
        margin-top: 0.82rem;
        font-weight: 500;
        font-size: 0.4rem;
        color: #000000;
        line-height: 0.56rem;
      }

      > .home-btn {
        margin-top: 0.12rem;
        width: 1.28rem;
        height: 0.4rem;
        position: relative;
      }
    }

    > .left-card {
      width: 5.3rem;
      height: 5.88rem;
    }

    > .right-card {
      width: 5.3rem;
      height: 5.88rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.12rem;

      > .top-card {
        width: 100%;
        height: 2.82rem;
      }

      > .bottom-card {
        width: 100%;
        height: 2.82rem;
      }
    }
  }
}

.home-mall {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.12rem;

  >.mall-card {
    width: 9.65rem;
    height: 100%;
    overflow: hidden;
    position: relative;

    &:hover {
      >img {
        transform: scale(1.1);
      }
    }

    &.mall-left {
      border-radius: 0 0.08rem 0.08rem 0;
      >img {
        border-radius: 0 0.08rem 0.08rem 0;
      }
    }

    &.mall-right {
      border-radius: 0.08rem 0 0 0.08rem;
      >img {
        border-radius: 0.08rem 0 0 0.08rem;
      }
    }

    >img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.4s;
    }

    >.mall-content {
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 2.18rem;
      background: rgba(250,251,254,0.1);
      backdrop-filter: blur(1rem);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >p {
        font-weight: 500;
        font-size: 0.56rem;
        color: #FFFFFF;
        line-height: 0.78rem;
      }

      >.btn-list {
        margin-top: 0.2rem;
        display: flex;
        justify-content: space-between;
        gap: 0.4rem;

        >.mall-btn {
          width: 2.12rem;
          height: 0.4rem;
          position: relative;
          padding: 0.06rem 0.2rem;

          > .text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            >img {
              width: 0.24rem;
              filter: brightness(0);
            }
          }

          &:hover, &:active, &:focus {
            > .text {
              >img {
                filter: brightness(1);
              }
            }
          }
        }
      }
    }
  }
}
</style>

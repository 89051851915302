<template>
  <div class="swiper">
    <div class="swiper-item" v-for="(item, i) in datas" :key="i" :class="getImgClass(i)"
         :ref='"img"+i' :style="{cursor: typeof item.event === 'function' ? 'pointer' : 'default'}"
         @click.stop="typeof item.event === 'function' ? item.event() : ''">
      <img :src="item.path" alt=""/>
      <p class="title">{{ item.title }}</p>
      <p class="desc">{{ item.desc }}</p>
    </div>
    <div class="swiper-prev" @click.stop="transPrev">
    </div>
    <div class="swiper-next" @click.stop="transNext">
    </div>
  </div>
</template>

<script>
export default {
  name: 'swiper',
  props: {
    datas: { //数据源（必填）
      type: Array,
      default: () => {
        return [];
      },
    },
    interval: { //轮播间隔（单位：ms）
      type: Number,
      default: () => {
        return 3000;
      },
    },
    animationInterval: { //轮播执行动画间隔（单位：ms）
      type: Number,
      default: () => {
        return 400;
      },
    },
    moveDistance: { //需触发轮播的鼠标移动x轴最小距离（单位：px）
      type: Number,
      default: () => {
        return 100;
      },
    },
  },
  data() {
    return {
      prevPrevIndex: -2,
      prevIndex: -1,
      currIndex: 0,
      nextIndex: 1,
      nextNextIndex: 2,
      nextNextNextIndex: 3,
      transing: false,
    };
  },
  mounted() {
    this.prevIndex = this.datas.length - 1;
    this.prevPrevIndex = this.datas.length - 2;
    this.refreshInterval();
  },
  unmounted() {
    clearInterval(this.intervalFun);
  },
  methods: {
    /**
     刷新定时器
     @param
     @return
     */
    refreshInterval() {
      clearInterval(this.intervalFun);
      this.intervalFun = setInterval(() => {
        if (this.$refs[`img${this.currIndex}`].length > 0) {
          this.transNext();
        }
      }, this.interval);
    },
    /**
     轮播滑动上一个
     @param
     @return
     */
    transPrev() {
      if (this.transing) {
        return;
      }
      this.transing = true;
      const nextNextNextImg = this.$refs[`img${this.nextNextNextIndex}`][0];
      nextNextNextImg.style.transition = `all ${this.animationInterval / 1000}s`;
      nextNextNextImg.style.transform = 'translateX(100%)';
      const nextNextImg = this.$refs[`img${this.nextNextIndex}`][0];
      nextNextImg.style.transition = `all ${this.animationInterval / 1000}s`;
      nextNextImg.style.transform = 'translateX(100%)';
      const nextImg = this.$refs[`img${this.nextIndex}`][0];
      nextImg.style.transition = `all ${this.animationInterval / 1000}s`;
      nextImg.style.transform = 'translateX(100%)';
      const currImg = this.$refs[`img${this.currIndex}`][0];
      currImg.style.transition = `all ${this.animationInterval / 1000}s`;
      currImg.style.transform = 'translateX(100%)';
      const prevImg = this.$refs[`img${this.prevIndex}`][0];
      prevImg.style.transition = `all ${this.animationInterval / 1000}s`;
      prevImg.style.transform = 'translateX(100%)';
      const prevPrevImg = this.$refs[`img${this.prevPrevIndex}`][0];
      prevPrevImg.style.transition = `all ${this.animationInterval / 1000}s`;
      prevPrevImg.style.transform = 'translateX(100%)';
      setTimeout(() => {
        this.transClear();
        this.currIndex = this.prevIndex;
        this.prevIndex = this.getPrevIndex(this.currIndex);
        this.prevPrevIndex = this.getPrevIndex(this.prevIndex);
        this.nextIndex = this.getNextIndex(this.currIndex);
        this.nextNextIndex = this.getNextIndex(this.nextIndex);
        this.nextNextNextIndex = this.getNextIndex(this.nextNextIndex);
      }, this.animationInterval);
      this.refreshInterval();
    },
    /**
     轮播滑动下一个
     @param
     @return
     */
    transNext() {
      if (this.transing) {
        return;
      }
      this.transing = true;
      const prevPrevImg = this.$refs[`img${this.prevPrevIndex}`][0];
      prevPrevImg.style.transition = `all ${this.animationInterval / 1000}s`;
      prevPrevImg.style.transform = 'translateX(-100%)';
      const prevImg = this.$refs[`img${this.prevIndex}`][0];
      prevImg.style.transition = `all ${this.animationInterval / 1000}s`;
      prevImg.style.transform = 'translateX(-100%)';
      const currImg = this.$refs[`img${this.currIndex}`][0];
      currImg.style.transition = `all ${this.animationInterval / 1000}s`;
      currImg.style.transform = 'translateX(-100%)';
      const nextImg = this.$refs[`img${this.nextIndex}`][0];
      nextImg.style.transition = `all ${this.animationInterval / 1000}s`;
      nextImg.style.transform = 'translateX(-100%)';
      const nextNextImg = this.$refs[`img${this.nextNextIndex}`][0];
      nextNextImg.style.transition = `all ${this.animationInterval / 1000}s`;
      nextNextImg.style.transform = 'translateX(-100%)';
      const nextNextNextImg = this.$refs[`img${this.nextNextNextIndex}`][0];
      nextNextNextImg.style.transition = `all ${this.animationInterval / 1000}s`;
      nextNextNextImg.style.transform = 'translateX(-100%)';
      setTimeout(() => {
        this.transClear();
        this.currIndex = this.nextIndex;
        this.prevIndex = this.getPrevIndex(this.currIndex);
        this.prevPrevIndex = this.getPrevIndex(this.prevIndex);
        this.nextIndex = this.getNextIndex(this.currIndex);
        this.nextNextIndex = this.getNextIndex(this.nextIndex);
        this.nextNextNextIndex = this.getNextIndex(this.nextNextIndex);
      }, this.animationInterval);
      this.refreshInterval();
    },
    /**
     轮播滑动清空
     @param
     @return
     */
    transClear() {
      this.transing = false;
      const currImg = this.$refs[`img${this.currIndex}`][0];
      currImg.style.transition = '';
      currImg.style.transform = '';
      const nextImg = this.$refs[`img${this.nextIndex}`][0];
      nextImg.style.transition = '';
      nextImg.style.transform = '';
      const nextNextImg = this.$refs[`img${this.nextNextIndex}`][0];
      nextNextImg.style.transition = '';
      nextNextImg.style.transform = '';
      const nextNextNextImg = this.$refs[`img${this.nextNextNextIndex}`][0];
      nextNextNextImg.style.transition = '';
      nextNextNextImg.style.transform = '';
      const prevImg = this.$refs[`img${this.prevIndex}`][0];
      prevImg.style.transition = '';
      prevImg.style.transform = '';
      const prevPrevImg = this.$refs[`img${this.prevPrevIndex}`][0];
      prevPrevImg.style.transition = '';
      prevPrevImg.style.transform = '';
    },
    /**
     获取上一个序号
     @param {Number} i 序号
     @return {Number} 上一个序号
     */
    getPrevIndex(i) {
      if (i == 0) {
        return this.datas.length - 1;
      }
      return i - 1;
    },
    /**
     获取下一个序号
     @param {Number} i 序号
     @return {Number} 下一个序号
     */
    getNextIndex(i) {
      if (i == this.datas.length - 1) {
        return 0;
      }
      return i + 1;
    },
    /**
     获取图片class
     @param {Number} i 序号
     @return {String} 图片class
     */
    getImgClass(i) {
      if (i == this.prevIndex) {
        return 'prev';
      } else if (i == this.prevPrevIndex) {
        return 'prev-prev';
      } else if (i == this.nextIndex) {
        return 'next';
      } else if (i == this.currIndex) {
        return 'curr';
      } else if (i == this.nextNextIndex) {
        return 'next-next';
      } else if (i == this.nextNextNextIndex) {
        return 'next-next-next';
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.swiper {
  position: relative;
  height: 100%;
  overflow: hidden;

  > .swiper-item {
    position: absolute;
    top: 0;
    left: -200%;
    width: 7.28rem;
    height: 9.68rem;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      border-radius: .08rem;
      transition: all 0.4s;
    }

    >.title {
      font-weight: 500;
      font-size: 0.28rem;
      color: #333333;
      line-height: 0.39rem;
      position: absolute;
      bottom: 8.19rem;
      width: 100%;
      text-align: center;
    }

    >.desc {
      font-size: 0.2rem;
      color: #666666;
      line-height: 0.28rem;
      position: absolute;
      bottom: 7.79rem;
      width: 100%;
      text-align: center;
    }

    &:hover {
      > img {
        transform: scale(1.1);
      }
    }
  }

  > .swiper-item.prev-prev {
    left: -12.54rem;
  }

  > .swiper-item.prev {
    left: -5.14rem;
  }

  > .swiper-item.curr {
    left: 2.26rem;
  }

  > .swiper-item.next {
    left: 9.66rem;
  }

  > .swiper-item.next-next {
    left: 17.06rem;
  }

  > .swiper-item.next-next-next {
    left: 24.46rem;
  }
}

.swiper-prev {
  position: absolute;
  top: 50%;
  left: .9rem;
  transform: translateY(-50%);
  z-index: 1;
  width: 0.6rem;
  height: 0.6rem;
  background: url("../img/swiper-left.png") no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;

  &:hover {
    background: url("../img/swiper-left-hover.png") no-repeat center center;
    background-size: 100% 100%;
  }
}

.swiper-next {
  position: absolute;
  top: 50%;
  right: .9rem;
  transform: translateY(-50%);
  z-index: 1;
  width: 0.6rem;
  height: 0.6rem;
  background: url("../img/swiper-right.png") no-repeat center center;
  background-size: 100% 100%;
  cursor: pointer;

  &:hover {
    background: url("../img/swiper-right-hover.png") no-repeat center center;
    background-size: 100% 100%;
  }
}
</style>
